<template>
	<div class="vacancy-info">
		<div>
			<img v-if="city" src="~/assets/images/map-marker.svg" /><span>{{ city }}</span>
		</div>
		<div>
			<img v-if="locationLabel" src="~/assets/images/hotel.svg" /><span>{{ locationLabel }}</span>
		</div>
		<div>
			<img v-if="workingHours" src="~/assets/images/clock.svg" /><span>{{ workingHours }}</span>
		</div>
	</div>
</template>

<script setup>
const { t } = useI18n();

const props = defineProps({
	city: { type: String, default: '' },
	workingHours: { type: String, default: '' },
	locations: { type: Array, default: () => [] },
});

const locationLabel = computed(() => {
	if (props.locations.length === 0) {
		return '';
	} else if (props.locations.length === 1) {
		return props.locations[0].header;
	} else {
		return t('variousHotels');
	}
});
</script>

<style lang="scss" scoped>
.vacancy-info {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	gap: 30px;

	div {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
	}

	img {
		width: 20px;
		height: 20px;
		margin-right: 5px;
	}
}
</style>
